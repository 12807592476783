#header {
  align-items: center;
  background-color: var(--wild-sand);
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 20px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

#header .overlap-group {
  background-image: url(/assets/img/ellipse-1-3.svg);
  background-size: 100% 100%;
  height: 20px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 20px;
}

#header .ellipse-7 {
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 12px;
}

#header .vector-8 {
  height: 9px;
  left: 10px;
  position: absolute;
  top: 2px;
  width: 9px;
}

#header .vector-7 {
  height: 10px;
  left: 9px;
  position: absolute;
  top: 1px;
  width: 10px;
}
