:root {
  --black: #000000;
  --eerie-black: #1c1919;
  --fantasy: #fbf3f4;
  --fruit-salad: #4ca154;
  --fuscous-gray: #565151;
  --mercury: #e7e4e4;
  --mojo: #c23f41;
  --purple-heart: #6340d4;
  --quick-silver: #a8a5a5;
  --tara: #e2fbe8;
  --white: #ffffff;
  --wild-sand: #f5f4f4;

  --font-size-l: 20px;
  --font-size-m: 18px;
  --font-size-s: 16px;
  --font-size-xl: 30px;
  --font-size-xs: 14px;
  --font-size-xxs: 8.1px;
  --font-size-xxxs: 7.1px;

  --black: #000000;
  --eerie-black: #1c1919;
  --fuscous-gray: #565151;
  --white: #ffffff;

  --font-size-l: 18px;
  --font-size-m: 14px;
  --font-size-xl: 20px;
  --font-size-xxl: 30px;

  --font-family-courier-regular: "Courier-Regular", Helvetica;
  --font-family-satoshi-medium: "Satoshi-Medium", Helvetica;
  --font-family-satoshi-regular: "Satoshi-Regular", Helvetica;

  --font-family-courier-regular: "Courier-Regular", Helvetica;
  --font-family-satoshi-bold: "Satoshi-Bold", Helvetica;
  --font-family-satoshi-medium: "Satoshi-Medium", Helvetica;
  --font-family-satoshi-regular: "Satoshi-Regular", Helvetica;
  --font-family-sf_pro-medium: "SF Pro-Medium", Helvetica;
  --font-family-courier-regular: "Courier-Regular", Helvetica;
  --font-family-inter-medium: "Inter-Medium", Helvetica;
  --font-family-inter-regular: "Inter-Regular", Helvetica;
  --font-family-inter-semibold: "Inter-SemiBold", Helvetica;
  --font-family-satoshi-medium: "Satoshi-Medium", Helvetica;
  --font-family-satoshi-regular: "Satoshi-Regular", Helvetica;
}
.label-2 {
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.label-1 {
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.body-2 {
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.body-1 {
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

#header {
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.display {
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.highlight {
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.text-xlregular {
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.text-xlbold {
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
}

.display-mdregular {
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
}

.text-lgbold {
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
}

.display-mdbold {
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
}

.satoshi-medium-eerie-black-8-1px {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 500;
}

.satoshi-regular-normal-quick-silver-7-1px {
  color: var(--quick-silver);
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.courier-regular-normal-eerie-black-20px {
  color: var(--eerie-black);
  font-family: var(--font-family-courier-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.satoshi-bold-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.satoshi-regular-normal-fuscous-gray-14px {
  color: var(--fuscous-gray);
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.satoshi-medium-eerie-black-24px {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-medium);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
