/* screen - landing */

.landing {
  align-items: center;
  background-color: var(--wild-sand);
  display: flex;
  flex-direction: column;
  gap: 96px;
  height: auto;
  position: relative;
  margin: 0 auto;
}

.landing .images img {
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  width: 100%;
  padding: 0 20px;
}

.landing .account {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .hero-content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.landing .badge-1 {
  background-color: var(--white);
  border-radius: 8px;
  gap: 4px;
  justify-content: center;
  padding: 4px 8px;
}

.landing .icon-rosewood {
  height: 16px;
  position: relative;
  width: 16px;
}

.landing .by-rosewood-creative {
  color: var(--eerie-black);
  font-family: var(--font-family-courier-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: -0.56px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .wrapper-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.landing .title {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-medium);
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.96px;
  line-height: 48px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: -moz-fit-content;
  width: 100%;
  padding: 0 20px;
  line-height: 120%;
}

.landing .radar-helps-you-trac {
  color: var(--fuscous-gray);
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 22px;
  position: relative;
  text-align: center;
  width: 452px;
}

.landing .button {
  align-items: center;
  background-color: var(--eerie-black);
  border-radius: 8.4px;
  display: inline-flex;
  gap: 11.2px;
  height: 50px;
  justify-content: center;
  overflow: hidden;
  padding: 5.6px 22.4px;
  position: relative;
}

.landing .log-in {
  color: var(--white);
  font-family: var(--font-family-satoshi-medium);
  font-size: 19.6px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing .dont-have-an-account-sign-up {
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.landing .span0 {
  letter-spacing: -0.04px;
}

.landing .span1 {
  letter-spacing: -0.04px;
  text-decoration: underline;
}

.landing .images {
  align-self: stretch;
  height: 409.72px;
  position: relative;
  width: 100%;
}

.landing .widgets-container {
  height: 410px;
  left: 93px;
  position: relative;
  width: 1254px;
}

.landing .widgets {
  align-items: flex-start;
  background-color: var(--white);
  border: 0.51px solid;
  border-color: var(--mercury);
  border-radius: 16px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 298px;
  left: 0;
  overflow: hidden;
  padding: 48px;
  position: absolute;
  top: 56px;
  width: 400px;
}

.landing .card-header {
  align-items: center;
  display: flex;
  height: 31.31px;
  justify-content: space-between;
  margin-left: -23.76px;
  margin-right: -23.76px;
  margin-top: -23.76px;
  position: relative;
  width: 351.52px;
}

.landing .content-left {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.landing .wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8.08px;
  position: relative;
  width: 100%;
}

.landing .primary-text {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .badge {
  background-color: var(--tara);
  border-radius: 4.04px;
  gap: 2.02px;
  height: 12.12px;
  justify-content: flex-end;
  padding: 0px 2.02px;
}

.landing .icon-arrow {
  height: 8.08px;
  position: relative;
  width: 8.08px;
}

.landing .text {
  color: var(--fruit-salad);
  font-weight: 500;
  line-height: 20px;
}

.landing .secondary-text {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 24px;
  margin-bottom: -1.18px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing .dots-three {
  height: 12.12px;
  position: relative;
  width: 12.12px;
}

.landing .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.08px;
  margin-left: -23.76px;
  margin-right: -23.76px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.landing .cell {
  gap: 6.06px;
}

.landing .icon-contained-icon-phosphor {
  background-color: var(--wild-sand);
  border-radius: 63.13px;
  height: 20.2px;
  overflow: hidden;
  position: relative;
  width: 20.2px;
}

.landing .file-plus {
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.landing .instagram {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .cell-1 {
  gap: 12.12px;
}

.landing .x3284 {
  letter-spacing: -0.16px;
  line-height: 12.1px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .line-2 {
  align-self: stretch;
  background-image: url(/assets/img/line-4-6.svg);
  background-size: 100% 100%;
  height: 1px;
  position: relative;
  width: 100%;
}

.landing .badge-2 {
  background-color: var(--fantasy);
  border-radius: 4.04px;
  gap: 2.02px;
  height: 12.12px;
  justify-content: flex-end;
  padding: 0px 2.02px;
}

.landing .text-17 {
  color: var(--mojo);
  font-weight: 500;
  line-height: 20px;
}

.landing .line-3 {
  align-self: stretch;
  background-image: url(/assets/img/line-4-7.svg);
  background-size: 100% 100%;
  height: 1px;
  position: relative;
  width: 100%;
}

.landing .line-4 {
  align-self: stretch;
  background-image: url(/assets/img/line-4-8.svg);
  background-size: 100% 100%;
  height: 1px;
  position: relative;
  width: 100%;
}

.landing .line-4-1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.landing .widgets-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 0.51px solid;
  border-color: var(--mercury);
  border-radius: 16px;
  box-shadow:
    0px 0.46px 1.12px #00000001,
    0px 1.11px 2.69px #00000003,
    0px 2.09px 5.06px #00000003,
    0px 3.72px 9.02px #00000004,
    0px 6.96px 16.88px #00000005,
    0px 16.67px 40.4px #00000008;
  display: flex;
  flex-direction: column;
  gap: 24.24px;
  height: 298px;
  left: 854px;
  overflow: hidden;
  padding: 24.24px;
  position: absolute;
  top: 56px;
  width: 400px;
}

.landing .card-header-1 {
  height: 31.31px;
}

.landing .primary-text-1 {
  font-family: var(--font-family-satoshi-medium);
  font-size: 15.2px;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -0.51px;
}

.landing .content-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8.08px;
  height: 193.94px;
  position: relative;
  width: 100%;
}

.landing .line-chart {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4.04px;
  position: relative;
  width: 100%;
}

.landing .wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4.04px;
  position: relative;
  width: 100%;
}

.landing .x15m {
  letter-spacing: 0;
  line-height: 10.1px;
  margin-top: -0.51px;
}

.landing .x-axis-item {
  letter-spacing: 0;
  line-height: 10.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .overlap-group-1 {
  height: 180px;
  position: relative;
  width: 333px;
}

.landing .chart-lines {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 179.8px;
  justify-content: space-between;
  left: 0;
  padding: 6.06px 0px;
  position: absolute;
  top: 0;
  width: 333.33px;
}

.landing .line {
  align-self: stretch;
  border: 0.25px dashed;
  border-color: var(--mercury);
  height: 1px;
  position: relative;
  width: 100%;
}

.landing .line-5 {
  align-self: stretch;
  border: 0.25px dashed;
  border-color: var(--mercury);
  height: 1px;
  margin-bottom: -1px;
  position: relative;
  width: 100%;
}

.landing .content-2 {
  align-items: flex-end;
  display: flex;
  height: 180px;
  left: 0;
  min-width: 333px;
  padding: 6.6px 54.5px;
  position: absolute;
  top: 0;
}

.landing .plot-vertical-bar-with-number {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8.08px;
  height: 123px;
  justify-content: flex-end;
  position: relative;
}

.landing .text-21 {
  letter-spacing: 0;
  line-height: 12.1px;
  margin-top: -1.89px;
}

.landing .rectangle-286 {
  height: 103.03px;
  position: relative;
  width: 12.12px;
}

.landing .plot-vertical-bar-with-number-1 {
  margin-left: 56px;
}

.landing .text-22 {
  letter-spacing: 0;
  line-height: 12.1px;
  margin-top: -29.67px;
}

.landing .rectangle-286-1 {
  height: 134.34px;
  margin-top: -11.62px;
}

.landing .plot-vertical-bar-with-number-2 {
  margin-left: 57px;
}

.landing .text-23 {
  letter-spacing: 0;
  line-height: 12.1px;
}

.landing .rectangle-286-2 {
  height: 70.2px;
}

.landing .y-axis {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing .y-axis-item {
  color: transparent;
  font-family: var(--font-family-satoshi-regular);
  font-size: var(--font-size-xxxs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 10.1px;
  margin-top: -0.51px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .name {
  letter-spacing: 0;
  line-height: 10.1px;
  margin-top: -0.51px;
}

.landing .feb {
  letter-spacing: 0;
  line-height: 10.1px;
  margin-top: -0.51px;
}

.landing .place {
  letter-spacing: 0;
  line-height: 10.1px;
  margin-top: -0.51px;
}

.landing .widgets-2 {
  align-items: flex-start;
  background-color: var(--white);
  border: 0.69px solid;
  border-color: var(--mercury);
  border-radius: 22px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 410px;
  left: 352px;
  overflow: hidden;
  padding: 48px;
  position: absolute;
  top: 0;
  width: 550px;
}

.landing .card-header-2 {
  height: 43.06px;
  margin-left: -14.67px;
  margin-right: -14.67px;
  margin-top: -14.67px;
}

.landing .wrapper-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 11.11px;
  position: relative;
  width: 100%;
}

.landing .primary-text-2 {
  line-height: 38px;
  margin-top: -0.69px;
}

.landing .badge-3 {
  background-color: var(--tara);
  border-radius: 5.56px;
  gap: 2.78px;
  height: 16.67px;
  justify-content: flex-end;
  padding: 0px 2.78px;
}

.landing .icon-arrow-up-right {
  height: 11.11px;
  position: relative;
  width: 11.11px;
}

.landing .secondary-text-1 {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 24px;
  margin-bottom: -0.25px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing .dots-three-1 {
  height: 16.67px;
  position: relative;
  width: 16.67px;
}

.landing .content-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 11.11px;
  height: 266.67px;
  margin-bottom: -14.67px;
  margin-left: -14.67px;
  margin-right: -14.67px;
  position: relative;
  width: 100%;
}

.landing .line-chart-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5.56px;
  position: relative;
  width: 100%;
}

.landing .wrapper-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 5.56px;
  position: relative;
  width: 100%;
}

.landing .x15m-1 {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -0.69px;
}

.landing .x-axis-item-1 {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .overlap-group1 {
  height: 247px;
  position: relative;
  width: 458px;
}

.landing .chart-lines-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 247.22px;
  justify-content: space-between;
  left: 0;
  padding: 8.33px 0px;
  position: absolute;
  top: 0;
  width: 458.33px;
}

.landing .line-1 {
  align-self: stretch;
  border: 0.35px dashed;
  border-color: var(--mercury);
  height: 1px;
  position: relative;
  width: 100%;
}

.landing .line-6 {
  align-self: stretch;
  border: 0.35px dashed;
  border-color: var(--mercury);
  height: 1px;
  margin-bottom: -1px;
  position: relative;
  width: 100%;
}

.landing .overlap-group-2 {
  height: 231px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 458px;
}

.landing .plot {
  height: 230px;
  left: 0;
  position: absolute;
  top: 0;
  width: 458px;
}

.landing .intersect {
  background-color: var(--purple-heart);
  border-radius: 4.17px/4.07px;
  height: 8px;
  left: 265px;
  position: absolute;
  top: 124px;
  width: 8px;
}

.landing .tool-tip {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 11.11px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: inline-flex;
  flex-direction: column;
  left: 282px;
  position: absolute;
  top: 94px;
}

.landing .tool-tip-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.56px;
  padding: 8.33px 16.67px;
  position: relative;
  width: 100%;
}

.landing .feb-04 {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 24px;
  margin-top: -0.69px;
}

.landing .line-7 {
  align-self: stretch;
  border: 0.35px solid;
  border-color: #f8f8f8;
  height: 1px;
  position: relative;
  width: 100%;
}

.landing .dot {
  background-color: var(--purple-heart);
  border-radius: 4.17px;
  height: 8.33px;
  position: relative;
  width: 8.33px;
}

.landing .new-followers {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 20px;
  margin-top: -0.69px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .text-25 {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  margin-top: -0.69px;
}

.landing .line-8 {
  align-items: flex-start;
  display: flex;
  height: 1px;
  justify-content: flex-end;
  left: 155px;
  min-width: 228px;
  padding: 0 113.4px;
  position: absolute;
  top: 116px;
  transform: rotate(-90deg);
}

.landing .line-9 {
  height: 228px;
  margin-top: -114.41px;
  transform: rotate(90deg);
  width: 1px;
}

.landing .y-axis-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 44.44px;
  position: relative;
  width: 100%;
}

.landing .name-1 {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -0.69px;
}

.landing .feb-1 {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -0.69px;
}

.landing .place-1 {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -0.69px;
}

.landing .badge-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.landing .card-header-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing .cell-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.landing .feb-2 {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .file-plus-5 {
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.landing .name-2 {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .place-2 {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .plot-vertical-bar-with-number-3 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 4.55px;
  height: 123px;
  justify-content: flex-end;
  position: relative;
}

.landing .primary-text-3 {
  color: var(--eerie-black);
  font-weight: 500;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .rectangle-286-3 {
  position: relative;
  width: 12.12px;
}

.landing .row-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing .text-1 {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing .x15m-2 {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
