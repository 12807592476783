/* screen - entry */

.entry {
  align-items: flex-start;
  background-color: #f5f4f4;
  display: flex;
  flex-direction: column;
  gap: 128px;
  position: relative;
  padding-top: 120px;
}
.entry .logo {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.entry .phosphor-radar {
  height: 24px;
  position: relative;
  width: 24px;
}

.entry .ellipse-7 {
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.entry .vector-8 {
  height: 9px;
  left: 10px;
  position: absolute;
  top: 2px;
  width: 9px;
}

.entry .vector-7 {
  height: 10px;
  left: 9px;
  position: absolute;
  top: 1px;
  width: 10px;
}

.entry .radar {
  color: var(--eerie-black);
  font-family: var(--font-family-courier-regular);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: 1.6px;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.entry .account-nav {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.entry .profile {
  height: 24px;
  position: relative;
  width: 24px;
}

.entry .account {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.entry .wrapper {
  align-items: center;
  align-self: stretch;
  gap: 96px;
  width: 100%;
}

.entry .wrapper-1 {
  align-items: flex-start;
  gap: 96px;
  max-width: 1200px;
  margin: 0 20px;
}

.entry .title {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  font-size: 30px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.entry .wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
  width: 100%;
}

.entry .header-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 48px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.entry .dashboards {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-xl);
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.entry .plus-circle {
  height: 24px;
  position: relative;
  width: 24px;
}

.entry .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  flex-wrap: wrap;
}

.entry .card {
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  overflow: hidden;
  padding: 48px 32px;
  position: relative;
  min-width: 300px;
  height: 300px;
  justify-content: center;
}
.bg-white {
  background-color: #fff;
}
.network-title {
  font-size: 20px;
}
.link-btn {
  cursor: pointer;
  text-decoration: underline;
}
.entry .frame-1547764367 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.entry .text {
  height: auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.entry .text-1 {
}

.entry .headphones {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 288px;
}

.entry .name {
  left: 122px;
}

.entry .beats {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-size: 20px;
  margin-bottom: 10px;
}

.entry .button {
  align-items: center;
  background-color: var(--eerie-black);
  border-radius: 6px;
  display: inline-flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
  color: #fff;
}

.entry .log-in {
  color: var(--white);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.entry .name-2 {
}

.entry .wrapper-3 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.widget-parent {
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 48px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  padding-bottom: 40px;
}

.react-grid-layout {
  margin: 0 25px;
}

.widget-parent .add-button {
  padding-top: 30px;
  margin-right: 20px;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 45px;
  padding: 30px 0 20px;
  font-size: 23px;
}
