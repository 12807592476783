/* screen - radar-home */

.radar-home {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.radar-home .account {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-xs);
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

iframe.linkedin-post {
  display: block;
  height: 500px;
}

.radar-home .content-area {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 64px;
  padding: 64px 0px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.radar-home .dash-header {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.radar-home .client-info {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 13px;
  margin-right: -40px;
  position: relative;
  width: 1240px;
}

.radar-home .avatar-1 {
  background-image: url(/assets/img//size-xl@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 23331px;
  height: 56px;
  position: relative;
  width: 56px;
}

.radar-home .title {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .secondary-text-1 {
  line-height: 28px;
}

.radar-home .filters {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.radar-home .dropdown-labeled-default {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.radar-home .time-period {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.radar-home .icon-contained-icon-1 {
  background-color: var(--mercury);
  border-radius: 125px;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.radar-home .icon {
  height: 24px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 24px;
}

.radar-home .this-month {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-right: -4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .this-month-1 {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .icon-chevron-down-1 {
  height: 24px;
  position: relative;
  width: 24px;
}

.radar-home .row {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 1200px;
}

.radar-home .widget-desktop-list-with-highlight-md {
  /* align-items: flex-start; */
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 48px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
}

.radar-home .card-header-1 {
}

.radar-home .wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.radar-home .primary-text {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .badge {
  align-items: center;
  background-color: var(--tara);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  height: 24px;
  padding: 2px 4px;
  position: relative;
}

.radar-home .icon-arrow-up-right {
  height: 16px;
  position: relative;
  width: 16px;
}

.radar-home .text-1 {
  color: var(--fruit-salad);
}

.radar-home .secondary-text {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.radar-home .content-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.radar-home .cell {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.radar-home .icon-contained-icon {
  background-color: var(--wild-sand);
  border-radius: 125px;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.radar-home .instagram {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .cell-number-and-change {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.radar-home .x3284 {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .text-2 {
  color: var(--fruit-salad);
}

.radar-home .line {
  align-self: stretch;
  background-image: url(/assets/img//line-4-1.svg);
  background-size: 100% 100%;
  height: 1px;
  position: relative;
  width: 100%;
}

.radar-home .badge-1 {
  align-items: center;
  background-color: var(--fantasy);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  height: 24px;
  padding: 2px 4px;
  position: relative;
}

.radar-home .icon-arrow-down-right {
  height: 16px;
  position: relative;
  width: 16px;
}

.radar-home .text-3 {
  color: var(--mojo);
}

.radar-home .text-4 {
  color: var(--fruit-salad);
}

.radar-home .text-5 {
  color: var(--fruit-salad);
}

.radar-home .line-4 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.radar-home .text-6 {
  color: var(--fruit-salad);
}

.radar-home .stack {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.radar-home .widget-desktop-highight-sm {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 16px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 285px;
  overflow: hidden;
  padding: 48px;
  position: relative;
  width: 384px;
}

.radar-home .card-header {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.radar-home .x285 {
  color: var(--fruit-salad);
  font-weight: 500;
  line-height: 23px;
  margin-bottom: -0.5px;
  margin-top: -2.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .icon-arrow-down-right-1 {
  height: 16px;
  position: relative;
  width: 16px;
}

.radar-home .x285-1 {
  color: var(--mojo);
  font-weight: 500;
  line-height: 23px;
  margin-bottom: -0.5px;
  margin-top: -2.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .top-content {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 48px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 824px;
  overflow: hidden;
  padding: 48px;
  position: relative;
  width: 1200px;
}

.radar-home .content-header {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1104px;
}

.radar-home .wrapper-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.radar-home .wrapper-3 {
  align-items: center;
  display: inline-flex;
  gap: 4px;
}

.radar-home .content-highlight {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .pencil-simple-line {
  height: 16px;
  position: relative;
  width: 16px;
}

.radar-home .wrapper-4 {
  align-items: center;
  display: inline-flex;
  gap: 4px;
}

.radar-home .secondary-text-2 {
  line-height: 20px;
  margin-top: -1px;
}

.radar-home .pencil-simple-line-1 {
  height: 12px;
  position: relative;
  width: 12px;
}

.radar-home .dots-three {
  height: 24px;
  position: relative;
  width: 24px;
}

.radar-home .wrapper-5 {
  display: inline-flex;
  gap: 28px;
  margin-bottom: -3px;
}

.radar-home .post-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
}

.radar-home .post-ig {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 24px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 535px;
  padding: 24px 0px 0px;
  position: relative;
  width: 336px;
  border-radius: 10px;
  overflow: hidden;
}

.radar-home .wrapper-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 24px;
  width: 100%;
}

.radar-home .post-header {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.radar-home .avatar {
  background-image: url(/assets/img//size-md@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 16665px;
  height: 40px;
  position: relative;
  width: 40px;
}

.radar-home .apple {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .date {
  color: var(--fuscous-gray);
  font-weight: 500;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menu-item {
  cursor: pointer;
}

.radar-home .plant-portraiture {
  align-self: stretch;
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 24px;
  position: relative;
  white-space: break-spaces;
}

.radar-home .menu-sub {
  position: absolute;
  background: #fff;
  right: 0;
  top: 97px;
  border: 1px #eee solid;
}

.radar-home .menu-sub.top-57 {
  top: 57px;
}

.radar-home .img {
  align-self: stretch;
  /* background-position: 50% 50%; */
  /* background-size: cover; */
  height: 306px;
  position: relative;
  /* width: 100%; */
  margin-top: auto;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.radar-home .img video {
  width: 100%;
}

.radar-home .tiktok.post-wrapper .img {
  height: 456px;
}

.radar-home .img img {
  object-fit: cover;
}

.radar-home .stat-set {
  flex-wrap: wrap;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 336px;
}

.radar-home .stat-display {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100px;
}

.radar-home .x47 {
  color: var(--black);
  font-weight: 400;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .engagememt {
  color: var(--black);
  font-weight: 500;
  line-height: 17px;
  position: relative;
  white-space: break-spaces;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  font-size: 12px;
}

.radar-home .percent {
  color: var(--black);
  font-weight: 400;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .post-yt {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  background-image: url(/assets/img//post---yt.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 535px;
  position: relative;
  width: 336px;
}

.radar-home .x18-m-views {
  color: var(--white);
  font-family: var(--font-family-sf_pro-medium);
  font-size: 24px;
  font-weight: 500;
  left: 25px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 485px;
  white-space: nowrap;
}

.radar-home .img-1 {
  align-self: stretch;
  background-image: url(/assets/img//img@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 336px;
  position: relative;
  width: 100%;
}

.radar-home .icon-arrow-up-right-6 {
  height: 16px;
  position: relative;
  width: 16px;
}

.radar-home .percent-3 {
  color: var(--black);
  font-weight: 400;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .secondary-text-3 {
  color: var(--fuscous-gray);
  font-weight: 400;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .text {
  font-weight: 500;
  line-height: 23px;
  margin-bottom: -0.5px;
  margin-top: -2.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.radar-home .wrapper-6 {
  flex: 0 0 auto;
  position: relative;
}

.radar-home .x47-6 {
  color: var(--black);
  font-weight: 400;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.close-btn {
  position: absolute;
  right: 30px;
  background: transparent;
  border: none;
}

.list__list {
  margin-top: 40px;
}

.side-nav-parent {
  position: fixed;
  right: 0;
  height: 100vh;
  top: 0;
  width: 400px;
  z-index: 9999;
  background: #fff;
  padding: 30px;
}

.list__card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.list__data {
  display: flex;
  align-items: center;
}

.admin-icon {
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100px;
  margin-right: 10px;
}

.list__name {
  margin-bottom: 0;
}
