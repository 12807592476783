/* screen - add-widget */

/*.add-widget-modal {
  align-items: center;
  background-color: var(--wild-sand);
  display: flex;
  flex-direction: column;
  height: 1950px;
  overflow: hidden;
  position: relative;
}*/

.add-widget-modal p.title {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  position: relative;
  white-space: normal;
  text-align: center;
  width: 200px;
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-xl);
}

.add-widget-modal .header-1 {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 64px;
  position: relative;
  width: 1440px;
}

.add-widget-modal .account {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.add-widget-modal .content-area {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 64px;
  height: 1886px;
  padding: 64px 120px;
  position: relative;
  width: 100%;
}

.add-widget-modal .avatar {
  background-image: url(/assets/img//size-xl@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 23331px;
  height: 56px;
  position: relative;
  width: 56px;
}

.add-widget-modal .dots-three {
  height: 24px;
  position: relative;
  width: 24px;
}

.add-widget-modal .navigation {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--mercury);
  display: inline-flex;
  gap: 8px;
  height: 64px;
  position: relative;
}

.add-widget-modal .tab-item {
  align-items: center;
  display: inline-flex;
  height: 64px;
  justify-content: center;
  padding: 8px 16px;
}

.add-widget-modal .tab-item-1 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-color: var(--eerie-black);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 64px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.add-widget-modal .dropdown-default {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.add-widget-modal .time-period {
  align-self: stretch;
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.add-widget-modal .this-month {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.add-widget-modal .starter-module {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--mercury);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 69px 410px;
  position: relative;
  width: 100%;
}

.add-widget-modal .frame-1547764387 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  padding: 8px;
}

.add-widget-modal .button {
  align-items: center;
  background-color: var(--eerie-black);
  border-radius: 6px;
  display: inline-flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
}

.add-widget-modal .log-in {
  color: var(--white);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.add-widget-modal .widget-management {
  align-items: flex-start;
  background-color: var(--white);
  gap: 48px;
  left: 168px;
  overflow: hidden;
  padding: 68px 48px;
  position: absolute;
  top: 96px;
  width: 1104px;
}

.add-widget-modal .title {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-medium);
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.add-widget-modal .section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.add-widget-modal .header {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.add-widget-modal .frame-1547764389 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 14px;
  width: 100%;
  flex-wrap: wrap;
}

.add-widget-modal .row {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
}

.add-widget-modal .widget-add {
  align-items: center;
  gap: 16px;
  height: 240px;
  justify-content: center;
  padding: 16px;
  position: relative;
  width: 320px;
}

.add-widget-modal .facebook-page-views-total {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  position: relative;
  text-align: center;
  width: 200px;
}

.add-widget-modal .button-1 {
  align-items: center;
  background-color: #d6d4d4;
  border-radius: 6px;
  display: inline-flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
}

.add-widget-modal .section-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.add-widget-modal .instagram {
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menu-item:hover {
  /*  background-color: #eee;*/
}

.menu-item img {
  width: 50px;
}

.add-widget-modal .frame-1547764389-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.add-widget-modal .frame {
  flex: 0 0 auto;
  position: relative;
}

.add-widget-modal .row-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
}

.add-widget-modal .widget {
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 16px;
  box-shadow:
    0px 0.91px 2.21px #00000001,
    0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003,
    0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005,
    0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
}
