/* screen - content-detall */

.content-detall {
  align-items: center;
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  height: 2265px;
  position: relative;
}

.content-detall .content-area {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 64px;
  height: 2201px;
  padding: 106px 120px;
  position: relative;
  width: 100%;
}

.content-detall .header-and-filter {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.content-detall .content-area-1 {
  height: 1156px;
  position: relative;
  width: 1200px;
}

.content-detall .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 72px;
  left: 0;
  min-height: 681px;
  position: absolute;
  top: 0;
  width: 475px;
}

.content-detall .frame-1547764407 {
  align-items: center;
  gap: 24px;
}

.content-detall .frame {
  height: 32px;
  position: relative;
  width: 32px;
}

.content-detall .frame-1547764 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
}

.content-detall .title {
  color: var(--eerie-black);
  font-weight: 700;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .frame-1547764394 {
  align-items: flex-end;
  flex: 0 0 auto;
  gap: 9px;
  justify-content: center;
}

.content-detall .youtube {
  color: var(--fuscous-gray);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .date {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .yt {
  align-items: flex-end;
  align-self: flex-end;
  background-image: url(/assets/img//post---yt.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 535px;
  padding: 26px 25px;
  width: 336px;
}

.content-detall .x18-m-views {
  color: var(--white);
  font-family: var(--font-family-sf_pro-medium);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 118px;
  white-space: nowrap;
}

.content-detall .flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 80px;
  left: 600px;
  min-height: 1188px;
  position: absolute;
  top: -28px;
  width: 591px;
}

.content-detall .dropdown-default {
  align-items: flex-start;
  align-self: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.content-detall .time-period {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.content-detall .this-month {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .frame-1547764411 {
  align-items: flex-start;
  flex-direction: column;
  gap: 48px;
}

.content-detall .impressions {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .text-7 {
  font-weight: 400;
}

.content-detall .views {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .text-8 {
  font-weight: 400;
}

.content-detall .unique-views {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .text-9 {
  font-weight: 400;
}

.content-detall .frame-1547764410 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
}

.content-detall .engagement-rate {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .percent {
  color: var(--black);
  font-weight: 400;
  line-height: 38px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .frame-1547764409 {
  align-items: flex-start;
  flex: 0 0 auto;
  gap: 47px;
}

.content-detall .frame-1547764-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80px;
}

.content-detall .text-1 {
  font-weight: 500;
  margin-top: -1px;
}

.content-detall .likes {
  color: var(--black);
  font-weight: 700;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .comments {
  color: var(--black);
  font-weight: 700;
  line-height: 24px;
  margin-right: -2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .shares {
  color: var(--black);
  font-weight: 700;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .favorites {
  color: var(--black);
  font-weight: 700;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .average-watch-time {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .x35s {
  color: var(--black);
  font-weight: 400;
  line-height: 38px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .total-view-time {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .x302h {
  color: var(--black);
  font-weight: 400;
  line-height: 38px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .of-followers-vs-no {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .of-followers-who-commented {
  color: var(--black);
  font-weight: 700;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .frame-1 {
  display: inline-flex;
  position: relative;
}

.content-detall .percent-3 {
  color: var(--black);
  font-weight: 400;
  line-height: 38px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-detall .text {
  color: var(--black);
  line-height: 38px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
