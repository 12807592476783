/* screen - widget-detail */

.widget-detail {
  align-items: center;
  background-color: var(--wild-sand);
  display: flex;
  flex-direction: column;
  height: 1950px;
  position: relative;
}

.widget-detail .header {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 64px;
  position: relative;
  width: 1440px;
}

.widget-detail .account {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .content-area {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 64px;
  height: 1886px;
  padding: 64px 120px;
  width: 100%;
}

.widget-detail .tion {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.widget-detail .avatar {
  background-image: url(/assets/img//size-xl@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 23331px;
  height: 56px;
  position: relative;
  width: 56px;
}

.widget-detail .dots-three {
  height: 24px;
  position: relative;
  width: 24px;
}

.widget-detail .navigation {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--mercury);
  display: inline-flex;
  gap: 8px;
  height: 64px;
  position: relative;
}

.widget-detail .tab-item {
  align-items: center;
  display: inline-flex;
  height: 64px;
  justify-content: center;
  padding: 8px 16px;
}

.widget-detail .tab-item-1 {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-color: var(--eerie-black);
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 64px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.widget-detail .dropdown-default {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.widget-detail .time-period {
  align-self: stretch;
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.widget-detail .this-month {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .starter-module {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--mercury);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 69px 410px;
  position: relative;
  width: 100%;
}

.widget-detail .frame-1547764387 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  position: relative;
}

.widget-detail .button {
  align-items: center;
  background-color: var(--eerie-black);
  border-radius: 6px;
  display: inline-flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
}

.widget-detail .log-in {
  color: var(--white);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .widget-management {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 16px;
  box-shadow: 0px 0.91px 2.21px #00000001, 0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003, 0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005, 0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 1262px;
  left: 168px;
  overflow: hidden;
  padding: 68px 48px;
  position: absolute;
  top: 96px;
  width: 1104px;
}

.widget-detail .title {
  color: var(--eerie-black);
  font-family: var(--font-family-satoshi-medium);
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .frame-1547764393 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  margin-bottom: -38px;
  position: relative;
  width: 100%;
}

.widget-detail .header-1 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.widget-detail .wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.widget-detail .total-page-views {
  color: var(--fuscous-gray);
  font-weight: 500;
  line-height: 38px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .frame-1547764389 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
}

.widget-detail .frame-1547764392 {
  align-items: center;
  align-self: stretch;
  background-color: var(--wild-sand);
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 836px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.widget-detail .widgets {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 16px;
  box-shadow: 0px 0.91px 2.21px #00000001, 0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003, 0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005, 0px 33px 80px #00000008;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 590px;
  overflow: hidden;
  padding: 48px;
  position: relative;
  width: 792px;
}

.widget-detail .card-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 62px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.widget-detail .content-left {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
}

.widget-detail .wrapper-1 {
  align-items: center;
  flex: 0 0 auto;
  gap: 16px;
}

.widget-detail .primary-text {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .badge {
  align-items: center;
  background-color: var(--tara);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  height: 24px;
  justify-content: flex-end;
  padding: 0px 4px;
  position: relative;
}

.widget-detail .icon-arrow-up-right {
  height: 16px;
  position: relative;
  width: 16px;
}

.widget-detail .text-14 {
  color: var(--fruit-salad);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .secondary-text {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .dots-three-1 {
  height: 24px;
  position: relative;
  width: 24px;
}

.widget-detail .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 384px;
  position: relative;
  width: 100%;
}

.widget-detail .line-chart {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
  width: 100%;
}

.widget-detail .wrapper-2 {
  align-items: flex-start;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
}

.widget-detail .x15m {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .x-axis-item {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .overlap-group1 {
  height: 356px;
  position: relative;
  width: 660px;
}

.widget-detail .chart-lines {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 356px;
  justify-content: space-between;
  left: 0;
  padding: 12px 0px;
  position: absolute;
  top: 0;
  width: 660px;
}

.widget-detail .line {
  align-self: stretch;
  border: 0.5px dashed;
  border-color: var(--mercury);
  height: 1px;
  position: relative;
  width: 100%;
}

.widget-detail .line-1 {
  align-self: stretch;
  border: 0.5px dashed;
  border-color: var(--mercury);
  height: 1px;
  margin-bottom: -1px;
  position: relative;
  width: 100%;
}

.widget-detail .overlap-group-1 {
  height: 333px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 660px;
}

.widget-detail .plot {
  height: 331px;
  left: 0;
  position: absolute;
  top: 0;
  width: 660px;
}

.widget-detail .intersect {
  background-color: var(--purple-heart);
  border-radius: 6px/5.87px;
  height: 12px;
  left: 381px;
  position: absolute;
  top: 179px;
  width: 12px;
}

.widget-detail .tool-tip {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 0.91px 2.21px #00000001, 0px 2.19px 5.32px #00000003,
    0px 4.13px 10.02px #00000003, 0px 7.37px 17.87px #00000004,
    0px 13.79px 33.42px #00000005, 0px 33px 80px #00000008;
  display: inline-flex;
  flex-direction: column;
  left: 406px;
  position: absolute;
  top: 135px;
}

.widget-detail .tool-tip-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 12px 24px;
  position: relative;
  width: 100%;
}

.widget-detail .feb-04 {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .line-2 {
  align-self: stretch;
  border: 0.5px solid;
  border-color: #f8f8f8;
  height: 1px;
  position: relative;
  width: 100%;
}

.widget-detail .dot {
  background-color: var(--purple-heart);
  border-radius: 6px;
  height: 12px;
  position: relative;
  width: 12px;
}

.widget-detail .new-followers {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .x3857 {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .line-3 {
  align-items: flex-start;
  display: flex;
  height: 1px;
  justify-content: flex-end;
  left: 223px;
  min-width: 329px;
  padding: 0 163.7px;
  position: absolute;
  top: 168px;
  transform: rotate(-90deg);
}

.widget-detail .line-4 {
  height: 329px;
  margin-top: -164.75px;
  transform: rotate(90deg);
  width: 1px;
}

.widget-detail .y-axis {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 64px;
  position: relative;
  width: 100%;
}

.widget-detail .jan {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .feb {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .place {
  color: var(--quick-silver);
  font-weight: 400;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.widget-detail .caret-down-6 {
  height: 16px;
  position: relative;
  width: 16px;
}

.widget-detail .content-1 {
  align-self: stretch;
  flex-direction: column;
  position: relative;
}

.widget-detail .icon-chevron-down-3 {
  height: 24px;
  position: relative;
  width: 24px;
}

.widget-detail .wrapper-3 {
  align-self: stretch;
  display: flex;
  position: relative;
  width: 100%;
}
