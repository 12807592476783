@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
/* The following line is used to measure usage of this code. You can remove it if you want. */
@import url("https://px.animaapp.com/66305b2cbaaa0b3de39e2d23.66305b2dbaaa0b3de39e2d26.UpYJrmR.hcp.png");

@font-face {
  font-family: "Courier-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/courier_[allfont.net].ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Satoshi-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Satoshi-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Satoshi-Variable.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/SF-Pro.ttf") format("truetype");
}

#kt_content_container {
  max-width: 1200px;
}

.modal {
  background: rgba(0, 0, 0, 0.45);
}

.modal-content {
  padding: 50px;
}

.icon-button {
  cursor: pointer;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
  background: #fff;
  z-index: 999;
  position: relative;
  height: auto;
}

.btn-primary {
  align-items: center;
  background-color: var(--eerie-black);
  border-radius: 6px;
  display: inline-flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
}

.add-widget-modal .modal-content {
  width: 1104px;
}

.add-widget-modal .modal-dialog {
  justify-content: center;
  display: flex;
  height: auto;
}

.widget-add .menu-item .btn.btn-primary.remove {
  align-items: center;
  background-color: #d6d4d4 !important;
  border-radius: 6px;
  display: inline-flex;
  border-color: #d6d4d4 !important;
  color: #fff;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

.h1,
h1 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 1.75rem;
  }
}

.h2,
h2 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.5rem;
  }
}

.h3,
h3 {
  font-size: calc(1.26rem + 0.12vw);
}

@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.35rem;
  }
}

.h4,
h4 {
  font-size: 1.25rem;
}

.h5,
h5 {
  font-size: 1.15rem;
}

.h6,
h6 {
  font-size: 1.075rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.button {
  border: none;
}

iframe {
  display: none;
}

body {
  background-color: var(--wild-sand);
  font-family: var(--font-family-satoshi-regular);
}

.screen a {
  display: contents;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.logo {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.logo-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.phosphor-radar {
  height: 24px;
  position: relative;
  width: 24px;
}

.overlap-group {
  background-image: url(/assets/img//ellipse-1-3.svg);
  background-size: 100% 100%;
  height: 20px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 20px;
}

.ellipse-7 {
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.vector-8 {
  height: 9px;
  left: 10px;
  position: absolute;
  top: 2px;
  width: 9px;
}

.vector-7 {
  height: 10px;
  left: 9px;
  position: absolute;
  top: 1px;
  width: 10px;
}

.radar {
  letter-spacing: 1.6px;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.account-nav {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.profile {
  height: 24px;
  position: relative;
  width: 24px;
}

.text-and-secondary-text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.drop {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 16px;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0px 16px;
  position: relative;
  width: 260px;
}

.input {
  align-items: center;
  display: flex;
  gap: 12px;
  position: relative;
  width: 128px;
}

.icon-chevron-down {
  height: 24px;
  position: relative;
  width: 24px;
}

.end {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  border-radius: 100px;
  padding: 4px;
}

.end:hover {
  background: #eee;
  cursor: pointer;
}

.page-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 64px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.start {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.beats {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.new-headphone-launch {
  color: var(--fuscous-gray);
  font-weight: 400;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.all-channels {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.caret-down {
  height: 16px;
  position: relative;
  width: 16px;
}

.plus-circle {
  height: 24px;
  position: relative;
  width: 24px;
}

.tab-controls {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-widgets {
  color: var(--black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.plus-circle-1 {
  height: 20px;
  position: relative;
  width: 20px;
}

.shade {
  background-color: #29252066;
  height: 1950px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.top-bar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.icon-button {
  align-items: flex-start;
  background-color: var(--wild-sand);
  border-radius: 9999px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px;
  position: relative;
}

.x {
  height: 24px;
  position: relative;
  width: 24px;
}

.facebook {
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.x-axis {
  align-items: flex-end;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chart {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.tab {
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.header-and-navigation {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.menu-sub {
  position: absolute;
  background: #fff;
  right: 0;
  top: 50px;
}

.menu-sub.active {
  display: block;
}
