/* screen - dasbhboard-landing-tab */

.dasbhboard-landing-tab {
  align-items: center;
  background-color: var(--wild-sand);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.dasbhboard-landing-tab .account {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dasbhboard-landing-tab .content-area {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 64px;
  padding: 64px 120px;
  position: relative;
  width: 100%;
}

.dasbhboard-landing-tab .avatar {
  background-image: url(/assets/img//size-xl@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 23331px;
  height: 56px;
  position: relative;
  width: 56px;
}

.dasbhboard-landing-tab .dots-three {
  height: 24px;
  position: relative;
  width: 24px;
}

.dasbhboard-landing-tab .navigation {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--mercury);
  display: flex;
  gap: 8px;
  height: 64px;
  position: relative;
  width: 100%;
}

.dasbhboard-landing-tab .tab-item {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-color: var(--eerie-black);
  display: inline-flex;
  height: 64px;
  justify-content: center;
  padding: 8px 16px;
}

.dasbhboard-landing-tab .tab-item-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  height: 64px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.dasbhboard-landing-tab .dropdown-default {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.dasbhboard-landing-tab .this-month {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dasbhboard-landing-tab .wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
  width: 100%;
}

.dasbhboard-landing-tab .starter-module {
  align-items: center;
  border: 1px solid;
  border-color: var(--mercury);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  padding: 69px 410px;
  position: relative;
}

.dasbhboard-landing-tab .frame-1547764387 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  margin-left: -277px;
  margin-right: -277px;
  padding: 8px;
  position: relative;
}

.dasbhboard-landing-tab .start-from-a-template {
  color: var(--black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.dasbhboard-landing-tab .button {
  align-items: center;
  background-color: var(--eerie-black);
  border-radius: 6px;
  display: inline-flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
}

.dasbhboard-landing-tab .log-in {
  color: var(--white);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.dasbhboard-landing-tab .frame-1547764387-1 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  margin-left: -235px;
  margin-right: -235px;
  padding: 8px;
  position: relative;
}

.dasbhboard-landing-tab .customize-tab {
  color: var(--black);
  font-weight: 500;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
