/* screen - login */

.login {
  align-items: center;
  background-color: #f5f4f4;
  display: flex;
  flex-direction: column;
  gap: 128px;
  margin: 0 auto;
}

input {
  border: none;
}

.login .header {
  height: 64px;
  justify-content: space-between;
  padding: 0px 64px;
  width: 1440px;
}

.login .logo {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.login .phosphor-radar {
  height: 24px;
  position: relative;
  width: 24px;
}

.login .ellipse-7 {
  height: 12px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.login .vector-8 {
  height: 9px;
  left: 10px;
  position: absolute;
  top: 2px;
  width: 9px;
}

.login .vector-7 {
  height: 10px;
  left: 9px;
  position: absolute;
  top: 1px;
  width: 10px;
}

.login .radar {
  color: var(--eerie-black);
  font-family: var(--font-family-courier-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 1.6px;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.login .account-nav {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.login .profile {
  height: 24px;
  position: relative;
  width: 24px;
}

.login .account {
  color: var(--eerie-black);
  font-weight: 500;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.login .frame-1547764388 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
  position: relative;
}

.login .login-form {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0.91px 2.21px #00000003, 0px 2.19px 5.32px #00000004,
    0px 4.13px 10.02px #00000005, 0px 7.37px 17.87px #00000006,
    0px 13.79px 33.42px #00000007, 0px 33px 80px #0000000a;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 48px;
  position: relative;
}

.login .header-1 {
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  width: 348px;
}

.login .title {
  color: var(--eerie-black);
  font-family: var(--font-family-inter-semibold);
  font-size: var(--font-size-xl);
  font-weight: 600;
  letter-spacing: -0.48px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.login .get-started-tracking {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.login .section {
  gap: 32px;
}

.login .section-item {
  gap: 16px;
}

.login .email {
  color: var(--eerie-black);
  font-family: var(--font-family-inter-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.login .form {
  padding: 18px 16px;
}

.login .natalierosewoodcreativecom {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: -0.28px;
  line-height: normal;
  margin-bottom: -1.5px;
  margin-top: -3.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.login .password {
  color: var(--eerie-black);
  font-family: var(--font-family-inter-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.login .form-1 {
  padding: 16px;
}

.login .radar2024 {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: -0.28px;
  line-height: normal;
  margin-top: -1.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.login .button {
  align-items: center;
  align-self: stretch;
  background-color: var(--eerie-black);
  border-radius: 6px;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  padding: 4px 16px;
  position: relative;
  width: 100%;
}

.login .log-in {
  color: #ffffff;
  font-weight: 500;
  line-height: 20px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  border: none;
}

.login .dont-have-an-account-sign-up {
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.login .span0 {
  letter-spacing: -0.04px;
}

.login .span1 {
  letter-spacing: -0.04px;
  text-decoration: underline;
}

.login .form-2 {
  align-items: center;
  background-color: #faf9f9;
  border-radius: 6px;
  display: flex;
  gap: 8px;
  height: 48px;
  overflow: hidden;
  position: relative;
  width: 348px;
}

.login .header-2 {
  align-items: center;
  display: flex;
  position: relative;
}

.login .section-1 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}
